.Root {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.MenuPanel {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    border-right: var(--color-100-primary) solid 5px;
    border-image: linear-gradient(to right, var(--color-border), var(--color-100-background)) 1 100%;
}

.ContentPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
}
