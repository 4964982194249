.bookmarksSourceViewTextArea {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.tokenText {
    display: inline-block;
    padding-right: 10px;
    padding-left: 5px;
    font-weight: bold;
}
