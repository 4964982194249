.LinkItemEditControl {
    display: inline-block;
}

.LinkItemText {
    display: inline-block;
    padding-right: 10px;
}

.LinkItemText a:link {
    color: var(--color-100-primary);
}

.LinkItemText a:visited {
    color: var(--color-100-primary);
}

.LinkItemText a:hover {
    background: var(--color-100-background);
}
