.MenuItem a:link {
    color: var(--color-100-primary);
}

.MenuItem a:visited {
    color: var(--color-100-primary);
}

.MenuItem a:hover {
    background: var(--color-100-background);
}

.MenuItemIcon {
    color: var(--color-100-primary);
}

.MenuItemText {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
}
