:root {
    --color-100-primary: #404446;
    --color-50-primary: #7d8589;
    --color-100-black: #000;
    --color-50-black: #7f7f7f;
    --color-20-black: #ccc;
    --color-border: #f1a760;
    --color-100-background: #ffb40b3b;
    --color-10-background: #fffaf0;
    --color-info: #ffb40b3b;
    --color-error: #c55b85;
    --color-white: #fff;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, "lucida grande", tahoma, verdana, arial, sans-serif;
    font-size: 10pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-10-background);
}

input,
textarea,
button {
    border-radius: 8px;
    border-color: var(--color-border);
}

.submit,
button {
    background-color: var(--color-100-background);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h4 {
    color: var(--color-100-primary);
    margin: 0 0 10px 0;
}

h5 {
    font-size: x-small;
    color: var(--color-50-primary);
    margin: 0 10px 3px 5px;
    width: 100%;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.flex-container-row {
    flex-direction: row;
    display: flex;
}

.flex-container-column {
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
}

.flex-grow {
    flex-grow: 1;
}

.max-width {
    width: 100%;
}

.text-center {
    text-align: center;
}

.big-icon {
    font-size: 18pt;
    color: var(--color-100-primary);
    margin: 5px;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0 2px 0 2px;
    padding: 0;
}

.link-button:hover {
    text-decoration: none;
    background: var(--color-100-background);
}

.link-button:focus {
    text-decoration: none;
}
