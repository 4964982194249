.content-header {
    background: var(--color-100-background);
    font-size: 12pt;
    font-weight: bold;
    padding: 4px 0 4px 0;
    color: var(--color-100-primary);
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border: var(--color-border) solid 1px;
    border-radius: 25px;
}

.content-separator {
    width: 100%;
    height: 10px;
}

.content-bottom {
    margin-top: 10px;
    border-top: var(--color-border) solid 10px;
    border-image-source: linear-gradient(180deg, var(--color-border) 0, var(--color-10-background) 7px, var(--color-10-background) 100%);
    border-image-slice: 10 10 10 10;
}

.content-padding {
    padding-left: 10px;
    padding-right: 10px;
}
